

































































/**
 * The Version Control protocol
 */
import {Component, Prop, Vue} from 'vue-property-decorator';
import TableComponent from '@/components/shared/table/Table.component.vue';
import {namespace} from 'vuex-class';
import Address from '@/models/Address';
import {IrrelevantKeys, VersionControl} from '@/misc/VersionControl';
import Gender from '@/models/user-attributes/Gender';
import EmploymentType from '@/models/user-attributes/EmploymentType';
import Denomination from '@/models/user-attributes/Denomination';
import PaymentType from '@/models/user-attributes/PaymentType';
import BillingDelivery from '@/models/user-attributes/BillingDelivery';
import UserInitialsComponent from '@/components/user/UserInitials.component.vue';
import UserInitialsConfig from '@/misc/UserInitialsConfig';
import User from '@/models/User';
import UserRole from '@/models/user-attributes/UserRole';
import ContactPerson from '@/models/ContactPerson';


const tenantStore = namespace('tenant');
const UserStore = namespace('user');

/**
 * Array for the Archive Table
 */
interface TableDataElement {
  vc_item: VersionControl;
  difference: string[];
  previous: string[];
  current: string[];
}


@Component({
  components: {TableComponent, UserInitialsComponent},
})
/**
 * A way of Visualising the Origin of an Object that extends VersionControl,
 * e.g. former versions of the Object are saved in the Backend
 */
export default class ArchiveComponent extends Vue {

  @UserStore.Getter('gender')
  private _gender!: Gender[];
  @UserStore.Getter('employmentTypes')
  private _employmentTypes!: EmploymentType[];
  @UserStore.Getter('denominations')
  private _denominations!: Denomination[];
  @UserStore.Getter('paymentTypes')
  private _paymentTypes!: PaymentType[];
  @UserStore.Getter('billingDeliveries')
  private _billingDeliveries!: BillingDelivery[];
  @UserStore.Getter('roles')
  private _roles!: UserRole[];


  private userConfig: UserInitialsConfig = {showTooltip: false};

  /**
   * An Array of all Versions of the VC Object(including current) ordered by Time( oldest = 0, current = last)
   */
  @Prop()
  private tableItems!: any[];

  get tableData(): TableDataElement[] {
    return this.createTableData();
  }

  /**
   *  calculates the differences between the versions
   */
  public createTableData(): TableDataElement[] {
    // revert so the most recent changes are on top
    this.tableItems.reverse();

    // grap all possible attributes and filter all irrelevant
    const attributes = Object.keys(this.tableItems[1]).filter((item: string) => !Object.values(IrrelevantKeys).includes(item));
    const tableData: TableDataElement[] = [];

    // for loop over each comparison
    for (let i = 1; i < this.tableItems.length; i++) {
      const difference: string[] = [];
      const previous: string[] = [];
      const current: string[] = [];
      // for loop over all relevant attributes
      for (const attribute of attributes) {
        let currentAtt = this.tableItems[i - 1]?.[attribute];
        let previousAtt = this.tableItems[i]?.[attribute];

        // handle for edge cases (e.g. populated attributes )
        switch (attribute) {
          case 'contactPerson': {
            if (!this.compareContactPersons(currentAtt, previousAtt)) {
              difference.push(attribute);
              previous.push(this.contactPersonToArchiveString(previousAtt));
              current.push(this.contactPersonToArchiveString(currentAtt));
            }
            break;
          }
          case 'address':
          case 'billingAddress': {
            if (!this.compareAddresses(previousAtt, currentAtt)) {
              difference.push(attribute);
              previous.push(this.addressToArchiveString(previousAtt));
              current.push(this.addressToArchiveString(currentAtt));
            }
            break;
          }
          case 'gender':
          case 'paymentType':
          case 'employmentType':
          case 'denomination':
          case 'billingDelivery': {
            if (currentAtt instanceof Object) {
              continue;
            } else {
              currentAtt = this.getEnumObjectValues(attribute)?.find((elem) => elem.id === currentAtt);
            }
            previousAtt = previousAtt ?
                this.getEnumObjectValues(attribute)?.find((elem) => elem.id === previousAtt) : undefined;
            if (!this.compareEnumObjects(currentAtt, previousAtt)) {
              difference.push(attribute);
              if (previousAtt) {
                previous.push(this.$t(`USER_DETAIL.${this.convertAttributeToTR(attribute)}.${previousAtt.name.toUpperCase()}`).toString());
                current.push(this.$t(`USER_DETAIL.${this.convertAttributeToTR(attribute)}.${currentAtt.name.toUpperCase()}`).toString());
              } else {
                previous.push('');
                current.push(this.$t(`USER_DETAIL.${this.convertAttributeToTR(attribute)}.${currentAtt.name.toUpperCase()}`).toString());
              }
            }
            break;
          }
          case 'euResident':
          case 'active':
          case 'skipLocationCheckOnWorkSessions':
          case 'employeeSignNeeded':
          case 'customerSignNeeded': {
            if (previousAtt !== currentAtt) {
              difference.push(attribute);
              previous.push(this.getStringFromBoolean(attribute, previousAtt));
              current.push(this.getStringFromBoolean(attribute, currentAtt));
            }
            break;
          }
          default: {
            if (previousAtt !== currentAtt) {
              difference.push(attribute);
              previous.push(previousAtt as string);
              current.push(currentAtt as string);
            }
          }
        }
      }
      tableData[i] = {
        vc_item: this.tableItems[i - 1],
        difference,
        previous,
        current,
      };
    }
    return tableData.filter((vc) => vc.difference.length !== 0);
  }

  /**
   * Compare the values of two Address-Objects
   */
  public compareAddresses(address1: Address, address2: Address): boolean {
    if (address1 && address2) {
      if (address1.street !== address2.street) {
        return false;
      }
      if (address1.houseNo !== address2.houseNo) {
        return false;
      }
      if (address1.postalCode !== address2.postalCode) {
        return false;
      }
      if (address1.city !== address2.city) {
        return false;
      }
      return address1.country === address2.country;
    } else {
      return !(address1 || address2);
    }
  }


  /**
   * Compare the values of two ContactPerson-Objects
   */
  public compareContactPersons(person1: ContactPerson, person2: ContactPerson): boolean {
    if (person1.firstName !== person2.firstName) {
      return false;
    }
    if (person1.lastName !== person2.lastName) {
      return false;
    }
    if (person1.email !== person2.email) {
      return false;
    }
    return person1.phone === person2.phone;
  }

  /**
   * Compares Objects with fixed values( enums )
   */
  public compareEnumObjects(object1: any, object2: any): boolean {
    if (object1 && object2) {
      return object1.id === object2.id;
    } else if (object1 || object2) {
      return false;
    }
    return true;
  }

  /**
   * Function that returns a string that represents the boolean value
   * @param type the name of the boolean attribute
   * @param flag the state of the atr´tribute (true or false)
   */
  public getStringFromBoolean(type: string, flag: boolean): string {
    switch (type) {
      case 'euResident': {
        return this.$t(`USER_DETAIL.NATIONALITY.${flag ? 'EU_CITIZEN' : 'NO_EU_CITIZEN'}`).toString();
      }
      case 'employeeSignNeeded':
      case 'customerSignNeeded': {
        return this.$t(flag ? 'GENERAL.YES' : 'GENERAL.NO').toString();
      }
      case 'active': {
        return this.$t(flag ? 'GENERAL.ACTIVE' : 'GENERAL.INACTIVE').toString();
      }
      case 'skipLocationCheckOnWorkSessions': {
        return this.$t(flag ? 'GENERAL.NO' : 'GENERAL.YES').toString();
      }
      default : {
        return '';
      }
    }
  }

  /**
   * converts The values of a ContactPerson-Object to a string that can be represented in the Archive
   * @param person A ContactPerson Object
   */
  public contactPersonToArchiveString(person: ContactPerson): string {
    return (person.firstName ?? '')
        + (person.lastName ? ' ' + person.lastName : '')
        + (person.phone ? ' | ' + person.phone : '')
        + (person.email ? ' | ' + person.email : '');
  }

  /**
   * converts The values of an Address-Object to a string that can be represented in the Archive
   * @param address An Address Object
   */
  public addressToArchiveString(address: Address): string {
    if (address && address.street && address.houseNo && address.postalCode && address.city) {
      return address.street + ' '
          + address.houseNo + '\n'
          + address.postalCode + ' '
          + address.city;
      // TODO: implement later when countries are relevant
      //  + this.$t(`GENERAL.COUNTRIES.${address.country?.toUpperCase()}`).toString();
    }
    return '-';
  }

  /**
   * universal getter function for enum values in user( eg Gender, paymentType..)
   */
  public getEnumObjectValues(type: string) {
    switch (type) {
      case 'gender':
        return this._gender;
      case 'employmentType':
        return this._employmentTypes;
      case 'denomination':
        return this._denominations;
      case 'paymentType':
        return this._paymentTypes;
      case 'billingDelivery':
        return this._billingDeliveries;
    }
  }

  /**
   * Converts the Attribute to the Form of the respective TranslateResult
   * @param type The Type of the Object as a string
   */
  public convertAttributeToTR(type: string) {
    switch (type) {
      case 'gender':
        return 'GENDER';
      case 'employmentType':
        return 'EMPLOYMENT_TYPE';
      case 'denomination':
        return 'DENOMINATION';
      case 'paymentType':
        return 'PAYMENT_TYPE';
      case 'billingDelivery':
        return 'BILLING_DELIVERY';
    }
  }

  // the checks if string is a REAL Date, because the isValid function of moment is not enough
  public isNumber(attribute: any): boolean {
    return Number.isInteger(+attribute);
  }

  /**
   * Directs you to the page of the User that changed the object, unless(!) the user is an Admin
   */
  public onAuthorClick(user: User) {
    if ((user.role as UserRole).superAdmin) {
      this.$router.push({
        name: 'userDetails', params: {
          tenantId: this.$route.params.tenantId,
          userId: user.id!,
        },
      });
    }
  }
}
