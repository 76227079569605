















































































































import {Component, Prop} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import {mixins} from 'vue-class-component';
import Location from '@/models/Location';
import ErrorMessageHandlerMixin from '@/helper/ErrorMessageHandler.mixin';
import {validationMixin} from 'vuelidate';
import TimeSchedule from '@/models/TimeSchedule';
import moment from 'moment';
import MenuWithPickerComponent from '@/components/shared/MenuWithPicker.component.vue';
import Job from '@/models/Job';
import {jobStoreActions, jobStoreGetter, jobStoreMutations} from '@/stores/job.store';
import {locationStoreGetter} from '@/stores/location.store';
import {getJobFrequency} from '@/helper/JobTimeHelper';

const CustomerStore = namespace('customer');
const JobStore = namespace('job');
const JobOccurrence = namespace('jobOccurrence');
const LocationStore = namespace('location');

/**
 *  Component to show the jobs of a location
 */
@Component({
  mixins: [validationMixin],
  validations: {},
  components: {
    MenuWithPickerComponent,
    UserInitialsComponent: () => import(
        /* webpackChunkName: "UserInitialsComponent" */
        '@/components/user/UserInitials.component.vue'),
    TimeSchedulePickerComponent: () => import(
        /* webpackChunkName: "jobPickerComponent" */
        '@/components/shared/TimeSchedulePicker.component.vue'),
    PaginationComponent: () => import(
        '@/components/shared/table/Pagination.component.vue'),
    ActionMenuComponent: () => import(
        '@/components/shared/table/ActionMenu.component.vue'),
  },
})
export default class LocationJobsOverviewComponent extends mixins(ErrorMessageHandlerMixin) {
  //region Store, Actions, Getter & Mutations
  @JobStore.Action(jobStoreActions.DELETE_JOB_ACTION)
  public deleteJobAction!: (JobId: string) => Promise<TimeSchedule>;
  @JobStore.Action(jobStoreActions.UPDATE_JOB_ACTION)
  public updateJobAction!: (payload: { job: Job }) => Promise<Job>;
  @JobStore.Action(jobStoreActions.LOAD_JOB_ACTION)
  public loadJobAction!: (JobId: string) => Promise<Job>;
  @JobStore.Mutation(jobStoreMutations.STORE_COLORS)
  public storeColors!: (colors: any) => void;
  @JobStore.Getter(jobStoreGetter.COLORS)
  public colors: any;
  @LocationStore.Getter(locationStoreGetter.LOCATION)
  public location!: Location;
  @JobStore.Mutation('clearCachedJobs')
  private clearCachedJobs!: () => void;

  @Prop({default: []})
  public jobs!: Job[];


  //#endregion
  public isLoading: boolean = true;
  public selectedJob: Job = new Job();
  public showChangeStatusDialog: boolean = false;
  public openDeleteDialog: boolean = false;
  private page: number = 1;
  private itemsPerPage: number = 0;
  private cancelDate: string = '';

  /**
   * Returns an error of objects with the needed information for the vuetify table
   */
  get tableHeaders(): any[] {
    return [
      {
        text: this.$t('JOB_OVERVIEW.HEADER.EMPLOYEE').toString(),
        value: 'driver',
        class: 'table_header--text',
        sortable: false,
      },
      {
        text: this.$t('JOB_OVERVIEW.HEADER.DATE').toString(),
        value: 'date',
        class: 'table_header--text',
        sortable: false,
      },
      {
        text: this.$t('JOB_OVERVIEW.HEADER.START_TIME').toString(),
        value: 'times',
        class: 'table_header--text',
        width: '15%',
      },
      {
        text: this.$t('JOB_OVERVIEW.HEADER.FREQ').toString(),
        value: 'weekDays',
        class: 'table_header--text',
        sortable: false,
      },
      {text: '', value: 'actions', class: 'table_header--text', sortable: false},
    ];
  }

  public async mounted() {
    this.location.jobs = this.sortOutDatedJobs(this.location.jobs!);
    this.isLoading = false;
  }

  /**
   * iterates through all the jobs and sorts the ones who are outDated to the bottom
   */
  public sortOutDatedJobs(items: Job[]): Job[] {
    // sorts every item in the passed array of jobs
    return items.sort((a, b) => {

      // checks if a or b is outdated
      const outDateA = a.timeSchedule.isOutDated;
      const outDateB = b.timeSchedule.isOutDated;

      // if a is outdated and b isn't
      if (outDateA && !outDateB) {
        return 1;
      }

      // if b is outdated and a isn't
      if (!outDateB && outDateA) {
        return -1;
      }

      // default return if both or none of them are outdated or an additional case occurred
      return -1;
    });
  }

  /**
   * Custom Sorting for the Clean Time Overview,
   * sorts according to descending or ascending and the
   * table header
   */
  public customFilter(items: Job[], sortBy: string[], sortDesc: boolean[], locale: string, customSorters?: any): Job[] {
    // Get Table Row and orientation to sort for
    const sortByHeader: string = sortBy[0];
    const sortByDesc: boolean = sortDesc[0];

    // return all items if no sorting is enabled
    if (sortByDesc === undefined && sortByHeader === undefined) {
      return this.sortOutDatedJobs(items);
    }

    // Sort according to the header
    switch (sortByHeader) {
        // TODO holger do name sort
      case 'date':
        // TODO: sort for the next job in timeline if wanted
        items = items.sort((a, b) => {
          if (sortDesc) {
            return 1;
          }
          return 0;
        });
        return this.sortOutDatedJobs(items);
      case 'topics':
        items = items.sort((a, b) => {
          // Sort with length
          if (sortByDesc) {
            // TODO give Jobs Topics
            return b.location!.topics.length - a.location!.topics.length;
          }
          return a.location!.topics.length - b.location!.topics.length;
        });

        return this.sortOutDatedJobs(items);
      case 'active':
        // Sort by convertig the booleans to numbers
        items.sort((a, b) => {
          if (sortByDesc) {
            return Number(b.active) - Number(a.active);
          }
          return Number(a.active) - Number(b.active);
        });
        return this.sortOutDatedJobs(items);
      default:
        return this.sortOutDatedJobs(items);
    }
  }

  /**
   * Method that is called when user clicks on a row
   * opens the edit popUp with the timeSchedule of the row
   */
  public onRowClicked = (item: Job) => this.onEditJob(item);

  public getJobDate(item: Job): string {
    // The start and end Date
    let date = moment(item.timeSchedule.startDate).format(`${this.$t('GENERAL.DATE_FORMATS.DATE')}`);
    if (item.timeSchedule.endDate && item.timeSchedule.endDate?.length > 0) {
      date += ' - ' + moment(item!.timeSchedule.endDate).format(`${this.$t('GENERAL.DATE_FORMATS.DATE')}`);
    }
    return date;
  }

  /**
   * Returns a string representation of the byWeekArray found in the timeSchedule
   */
  public getJobFrequency = (timeSchedule: TimeSchedule) => getJobFrequency(timeSchedule, this.$i18n);

  /**
   * Deletes a job, by sending a request to the api
   * @param jobId
   */
  public async onDeleteJob(jobId: string): Promise<void> {
    try {
      // Send API Request to delete the timeSchedule with the given id
      await this.deleteJobAction(jobId);

      // try to find the given Job in our location Jobs array
      const indexToDelete = this.jobs.findIndex((job) => job.id === jobId);

      // If found delete from array
      if (indexToDelete > -1) {
        this.jobs.splice(indexToDelete, 1);
      }

      // Show Success message
      this.$notifySuccessSimplified('CUSTOMER_DASHBOARD.NOTIFICATIONS.DELETE_JOB.SUCCESS');

      // Clear Calendar Cache on Remove
      this.clearCachedJobs();

      // Close Delete Dialog
      this.openDeleteDialog = false;
      // catch all errors
    } catch (e) {
      // Job was already deleted (by another user)
      if (e.status === 409) {
        this.$notifyErrorSimplified('CUSTOMER_DASHBOARD.NOTIFICATIONS.DELETE_JOB.ERROR.RUNNING');
      } else {
        this.$notifyErrorSimplified('CUSTOMER_DASHBOARD.NOTIFICATIONS.DELETE_JOB.ERROR.GENERAL');
      }
    }
  }

  /**
   * Changes the Active Status of a Clean Time by sending an api Request
   */
  public async onChangeStatusOfJob(): Promise<void> {
    try {
      // Copy Job as its own object
      const jobCopy = Job.parseFromObject(this.selectedJob);
      // Set Status for this Clean Time
      jobCopy.active = !this.selectedJob.active;
      // Remove Recurrence from times array
      // Check if byWeekDay is the full week
      if (jobCopy.timeSchedule.weekdays!.length >= 7) {
        jobCopy.timeSchedule.weekdays = [];
      }
      // Send API Request to update the timeSchedule with the given id
      const updated = await this.updateJobAction({job: jobCopy});
      this.clearCachedJobs();
      // If API send back a timeSchedule Document is probably deleted
      if (updated) {
        // Clean Time Updated, update in our local list
        const indexToUpdate = this.location.jobs!.findIndex((curJob) => curJob.id === updated.id);
        // If found update in array
        if (indexToUpdate > -1) {
          this.location.jobs?.splice(indexToUpdate, 1, updated);
        }
        this.showChangeStatusDialog = false;
        // Show Success message
        this.$notifySuccessSimplified('CUSTOMER_DASHBOARD.NOTIFICATIONS.EDIT_JOB.SUCCESS');
      }
      // catch all errors
    } catch (e) {
      this.$notifyErrorSimplified('CUSTOMER_DASHBOARD.NOTIFICATIONS.EDIT_JOB.ERROR');
    }
  }

  public onEditJob(job: Job): void {
    this.$emit('editJob', job);
  }

  private getColor(color: string): string {
    return this.$colorHandler.getThemeColor(color);
  }

  public getJobTime(item: Job) {
    return item.timeSchedule.startTime?.hour.toString().padStart(2, '0')
        + ':' + item.timeSchedule.startTime?.minute.toString().padStart(2, '0');
  }

  private validFromMinMax: { min: string, max: string } = {
    min: moment().toISOString(),
    max: '',
  };

  private onStatusToggleClick(item: any) {
    this.selectedJob = item;
    this.showChangeStatusDialog = true;
  }

  private onDeleteClick(item: any) {
    this.selectedJob = item;
    this.openDeleteDialog = true;
  }
}
