





















































































import {Component, Vue} from 'vue-property-decorator';
import Location from '@/models/Location';
import {namespace} from 'vuex-class';
import User from '@/models/User';
import JobManageComponent from '@/components/job/JobManage.component.vue';
import Customer from '@/models/Customer';
import ColorMapperObject from '@/types/ColorMapperObject';
import RJTabs from '@/components/shared/custom-vuetify/RJTabs.vue';
import LocationArchiveComponent from '@/components/location/LocationArchive.component.vue';
import {TabItem} from '@/interfaces/TabItem';
import Job from '@/models/Job';
import {locationStoreActions, locationStoreGetter, locationStoreMutations} from '@/stores/location.store';
import {customerStoreActions} from '@/stores/customer.store';
import LocationJobsOverviewComponent from '@/components/location/LocationJobsOverview.component.vue';
import {jobStoreActions, jobStoreGetter, jobStoreMutations} from '@/stores/job.store';
import {jobOccurrenceStoreActions, jobOccurrenceStoreMutations} from '@/stores/jobOccurrence.store';
import {Permission} from '@/misc/Enums/permission.enum';
import {userStoreActions} from '@/stores/user.store';
import LocationFilesComponent from '@/components/location/LocationFiles.component.vue';

const UserStore = namespace('user');
const JobOccurrenceStore = namespace('jobOccurrence');
const JobStore = namespace('job');
const LocationStore = namespace('location');
const CustomerStore = namespace('customer');

@Component({
  components: {
    LocationFilesComponent,
    LocationJobsOverviewComponent,
    LocationArchiveComponent,
    RJTabs,
    LocationTimeScheduleOverviewComponent: () => import(
        /* webpackChunkName: "LocationtimeScheduleOverviewComponent" */
        '@/components/location/LocationJobsOverview.component.vue'),
    LocationManageSpecificDataComponent: () => import(
        /* webpackChunkName: "LocationManageSpecificDataComponent" */
        '@/components/location/LocationManageSpecificData.component.vue'),
    JobManageComponent: () => import(
        /* webpackChunkName: "JobManage" */
        '@/components/job/JobManage.component.vue'),
    LocationMasterData: () => import(
        /* webpackChunkName: "LocationMasterData" */
        '@/components/location/LocationMasterData.component.vue'),
    LocationManageMasterDataComponent: () => import(
        /* webpackChunkName: "LocationManageMasterDataComponent" */
        '@/components/location/LocationManageMasterData.component.vue'),
  },
})
export default class LocationDashboardView extends Vue {

  @JobStore.Mutation(jobStoreMutations.STORE_COLORS)
  public storeColors!: (colors: any) => void;
  @LocationStore.Mutation(locationStoreMutations.STORE_LOCATION)
  public storeLocationMutation!: (location: Location | undefined) => void;
  @CustomerStore.Action(customerStoreActions.LOAD_COLOR)
  public loadColorAction!: (timeScheduleId: string) => Promise<ColorMapperObject>;
  @LocationStore.Action(locationStoreActions.LOAD_LOCATION_ACTION)
  public loadLocationAction!: (payload: { locationId: string, store: boolean }) => Promise<Location>;
  @LocationStore.Action(locationStoreActions.DELETE_LOCATION_ACTION)
  public deleteLocationAction!: (locationId: string) => void;
  @UserStore.Action(userStoreActions.LOAD_USERS_ACTION)
  private loadUsersAction!: (payload: { tenantId: string }) => Promise<User[]>;
  @JobStore.Action(jobStoreActions.LOAD_JOB_ACTION)
  public loadJobAction!: (jobId: string) => Promise<Job>;
  @JobStore.Action(jobStoreActions.LOAD_JOBS_ACTION)
  public loadJobsAction!: (locationId: string) => Promise<Job[]>;
  @JobOccurrenceStore.Action(jobOccurrenceStoreActions.CANCEL_REQUESTS_ACTION)
  public cancelJobRequestsAction!: () => void;
  @JobStore.Getter(jobStoreGetter.COLORS)
  public colors: any;
  /**
   * Model for tabs component
   */
  public tabsModel: number = 0;

  /**
   * Job that get sent insidie the JobsOverview
   */
  public jobs: Job[] = [];

  /**
   * boolean to reset the overview inside html
   */
  public resetOverview: boolean = false;
  /**
   * boolean to show the jobDialog
   */
  public addJobDialog: boolean = false;
  /**
   * bool to prevent showing wrong data in MasterDataComponent. Otherwise the name may be wrong
   */
  public showData: boolean = false;
  private showEditModal: boolean = false;
  private showDeleteDialog: boolean = false;
  @CustomerStore.Action(customerStoreActions.LOAD_CUSTOMER_ACTION)
  private loadCustomerAction!: (customerId: string) => Promise<Customer[]>;
  @LocationStore.Action(locationStoreActions.EDIT_LOCATION_ACTION)
  private editLocationAction!: (arg: { location: Location, shouldBeStored: boolean }) => Promise<Location>;



  @LocationStore.Getter(locationStoreGetter.LOCATION)
  public _location!: Location;

  public get location() {
    return this._location;
  }

  private get locationTabs(): TabItem[] {
    return [
      {
        key: 'masterdata',
        text: this.$t('GENERAL.MASTER_DATA').toString(),
        available: this.$userRoleHandler.hasPermission(Permission.LOCATION_READ_OWN),
      }, {
        key: 'jobs',
        text: this.$t('JOB_OVERVIEW.VIEWS.LIST').toString(),
        available: this.$userRoleHandler.hasPermission(Permission.LOCATION_READ_OWN),
      }, {
        key: 'pdf',
        text: this.$t('LOCATIONS_DASHBOARD.PDF').toString(),
        available: this.$userRoleHandler.hasPermission(Permission.LOCATION_READ_OWN) && !!this.location.files?.length,
      }, {
        key: 'archive',
        text: this.$t('VERSION_CONTROL.ARCHIVE').toString(),
        available: false, // this.$userRoleHandler.hasPermission(Permission.LOCATION_UPDATE_OWN),
      },
    ];
  }

  public async closeManageComponent(updatedItem: Location) {
    if (updatedItem) {
      await this.loadLocationAction({locationId: this.location.id!, store: true});
    }
    this.showEditModal = false;
  }

  // TODO: stacking delay
  /**
   * shows the dialog box
   */
  public showJobDialog(editMode: boolean = false, apiJob?: Job): void {
    let showDelay = 0;
    // Reset Job Dialog and show it in the end.
    if (this.addJobDialog) {
      this.addJobDialog = false;
      showDelay = 200;
    }

    const timeout = setTimeout(() => {
      this.addJobDialog = true;
      // if job dialog should be opened in edit mode
      // Wait a dom update to tell the job manage component to do so
      // waits a dom update and inserts the existing timeSchedule data to the form
      // Call the Edit method for the timeSchedule
      (this.$refs.jobManageComponent as JobManageComponent).setEditJob(editMode ? apiJob! : new Job(), editMode);
      clearTimeout(timeout);
    }, showDelay);
  }

  /**
   * Opens edit dialog of a timeSchedule
   */
  public async onEditJob(job: Job): Promise<void> {
    try {
      // get TimeSchedule from api
      const apiJob = await this.loadJobAction(job.id!);


      // opens the dialog box
      this.showJobDialog(true, apiJob);
    } catch (e) {
      if (e.status === 404) {
        // Shows an error message that the timeSchedule is already deleted and cannot be edited
        this.$notifyErrorSimplified('JOB_OVERVIEW.ERRORS.JOB_ALREADY_DELETED');
      } else {
        // Shows a generic error message that the timeSchedule cannot be edited
        this.$notifyErrorSimplified('JOB_OVERVIEW.ERRORS.COULD_NOT_EDIT_JOB');
      }
    }
  }

  /**
   * Hides the Job Dialog, if abort was pressed
   */
  public hideJobDialog(): void {
    this.addJobDialog = false;
  }

  /**
   * Updates a Job in our location Job array if an edit succeeds
   */
  public updateJobEntry(job: Job): void {
    // try to find the passed clean time in our location array
    const indexToUpdate = this.location.jobs!.findIndex((entry) => entry.id === job.id);

    // if found replace data, with the updated ones
    if (indexToUpdate > -1) {
      // Replace existing TimeSchedule with our updated one
      // this executes a dom reload
      this.location.jobs!.splice(indexToUpdate, 1, job);

      // Store our current edited location once more in our location store
      this.storeLocationMutation(this.location);
    }

    // Clear Jobs, so that the Calendar will reload it's data
    this.reloadJobs();
  }

  /**
   * Add Job to the locations list of Jobs
   */
  public async addCreatedJob(job: Job): Promise<void> {
    // add Job to location
    this.location.jobs!.push(job);

    // Load new Color for this job,
    // store it in our state
    const currentColors = this.colors;
    this.storeColors(currentColors);

    // update location in state
    this.storeLocationMutation(this.location);

    // Clear Jobs, so that the Calendar will reload its data
    await this.reloadJobs();
  }

  /**
   * Clears all Jobs and Cache from the JobStore
   */
  public async reloadJobs() {
    // Clear Jobs, so that the Calendar will reload it's data
    this.cancelJobRequestsAction();
    this.jobs = await this.loadJobsAction(this.location.id!);
  }

  private async onDeleteItem() {
    try {
      await this.deleteLocationAction(this.location.id!);
      this.$notifySuccessSimplified('CUSTOMER_DASHBOARD.NOTIFICATIONS.LOCATION_DELETE.SUCCESS');

      await this.$router.push({
        name: 'locationsOverview',
        params: {tenantId: this.$route.params.tenantId},
      });

    } catch (e) {
      switch (e.status) {
        case 409: {
          this.$notifyErrorSimplified('CUSTOMER_DASHBOARD.NOTIFICATIONS.LOCATION_DELETE.RUNNING');
          break;
        }
        default: this.$notifyErrorSimplified('CUSTOMER_DASHBOARD.NOTIFICATIONS.LOCATION_DELETE.ERROR');
      }
    }
  }

  private async created() {
    await this.initialize();
    this.jobs = await this.loadJobsAction(this.location.id!);
  }

  private async initialize() {
    try {
     // await this.loadUsersAction({tenantId: this.$route.params.tenantId});
      await this.loadLocationAction({locationId: this.$route.params.locationId, store: true});
      this.showData = true;
    } catch (e) {
      this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.GENERAL_ERROR');
    }
  }
}
