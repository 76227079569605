


















/**
 * The Version Control protocol
 */
import {Component, Prop, Vue} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import ArchiveComponent from '@/components/shared/Archive.component.vue';
import Location from '@/models/Location';
import {VersionControl} from '@/misc/VersionControl';
import {locationStoreActions, locationStoreGetter} from '@/stores/location.store';
import DBFile from '@/interfaces/DBFile';

const LocationStore = namespace('location');

@Component({
  components: {},
})
export default class LocationFilesComponent extends Vue {

  @LocationStore.Action(locationStoreActions.DOWNLOAD_FILE)
  public downloadFile!: (payload: {locationId: string, fileId: string}) => Blob;


  @Prop({default: []})
  public files!: DBFile[];

  public async onDownloadFileClick(item: DBFile) {
    const blob = await this.downloadFile({locationId: item.locationId!, fileId: item.id});
    saveAs(blob, item.name);
  }

}
