




/**
 * The Version Control protocol
 */
import {Component, Prop, Vue} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import ArchiveComponent from '@/components/shared/Archive.component.vue';
import Location from '@/models/Location';
import {VersionControl} from '@/misc/VersionControl';
import {locationStoreActions, locationStoreGetter} from '@/stores/location.store';

const LocationStore = namespace('location');

@Component({
  components: {ArchiveComponent},
})
export default class LocationArchiveComponent extends Vue {

  @LocationStore.Action(locationStoreActions.LOAD_LOCATION_ORIGIN)
  private loadLocationOrigin!: (locationId: string) => Promise<Location[]>;
  @LocationStore.Getter(locationStoreGetter.LOCATION)
  public _location!: Location;

  private locationForArchive: Location = new Location();
  private locationVersions: Location[] = [];
  private loaded: boolean = false;

  public async mounted() {
    this.locationForArchive = Location.parseFromObject(this._location);
    this.locationVersions = await this.loadLocationOrigin(this.locationForArchive.id!);
    this.locationVersions.sort((vc1, vc2) => VersionControl.compareLogDate(vc1, vc2));
    this.locationVersions.push(this.locationForArchive);
    this.loaded = true;
  }
}
